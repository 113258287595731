import { createSlice } from "@reduxjs/toolkit";

// Define the initial state for the authentication slice
const initialState = {
  isLoggedIn: false, // Indicates if the user is logged in or not
  userDetails: null, // Stores the details of the authenticated user
  following: {}, //stores following data
  votingData: {},
  pathBeforeLogin: "/",
  captchaToken: "",
};

// Create the authentication slice using `createSlice`
export const authSlice = createSlice({
  name: "auth", // Name of the slice
  initialState,
  reducers: {
    // Reducer function for handling successful login
    loginSuccess: (state, action) => {
      const { payload } = action; // Payload contains the user details
      state.isLoggedIn = !!payload; // Set isLoggedIn to true if payload is not empty
      state.userDetails = state.userDetails
        ? { ...state.userDetails, ...payload }
        : payload; // Merge the user details with the existing state or set it as the new details
    },
    // Reducer function for setting user details
    setUser: (state, action) => {
      const { payload } = action; // Payload contains the user details
      state.userDetails = state.userDetails
        ? { ...state.userDetails, ...payload }
        : payload; // Merge the user details with the existing state or set it as the new details
    },
    // Reducer function for handling logout
    logoutUser: (state, action) => {
      state.isLoggedIn = false; // Set isLoggedIn to false
      state.userDetails = null; // Reset user details to null
      state.following = {};
    },
    appendFollowing: (state, actions) => {
      state.following = { ...state.following, ...actions.payload };
    },
    appendVoting: (state, actions) => {
      state.votingData = { ...state.votingData, ...actions.payload };
    },
    setBeforeLoginPath: (state, action) => {
      state.pathBeforeLogin = action.payload;
    },
    setCaptchaToken: (state, action) => {
      state.captchaToken = action.payload;
    },
  },
});

// Extract the action creators generated by `createSlice`
export const {
  loginSuccess,
  setUser,
  logoutUser,
  appendFollowing,
  appendVoting,
  setBeforeLoginPath,
  setCaptchaToken,
} = authSlice.actions;

// Export the reducer function generated by `createSlice`
export default authSlice.reducer;
