import React, { useEffect, useState } from "react";
import Home from "./Home";
import Contest from "./Contest";
import { useLocation, useNavigate } from "react-router-dom";
import { getLeaderboardData, gluedinLogin, refreshToken } from "../services";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess } from "../store/slices/auth-slice";
import {
  setLanguage,
  setLanguageID,
  setLeaderboardData,
  setLeaderboardUserData,
} from "../store/slices/common-slice";
import { showApiErrors } from "../utils/helpers";
import usePopup from "../utils/hooks/use-popup";
import useAuth from "../utils/hooks/use-auth";
import { format } from "date-fns";
import { showTourGuide } from "../store/slices/common-slice";
import { displayContestTourTrigger } from "../store/slices/suprise-me";

const HomeIndex = () => {
  const [firstTime, setFirstTime] = useState(true);
  const { isLoggedIn } = useAuth();
  const { openUniqueCodePopup } = usePopup();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ipDetailsInfo = useSelector((state) => state?.common?.ipDetailsInfo);
  const checkToken = new URLSearchParams(location.search).get("refresh");

  const windowLayerPush = (flag) => {
    window.dataLayer = window.dataLayer || [];

    let indexToUpdate = -1;
    for (let i = 0; i < window.dataLayer.length; i++) {
      if (window.dataLayer[i].loginEvent) {
        indexToUpdate = i;
        break;
      }
    }

    if (indexToUpdate !== -1) {
      // Update the existing shareContent entry
      window.dataLayer[indexToUpdate].loginEvent = {
        loggedInEvent: flag,
      };
    } else {
      // Add a new shareContent entry
      window.dataLayer.push({
        loginEvent: {
          loggedInEvent: flag,
        },
      });
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      windowLayerPush(true);
    } else {
      windowLayerPush(false);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchLeaderBoardData();
    }
    if (localStorage.getItem("isFirstVisit") && !checkToken) {
      setFirstTime(false);
    } else {
      // navigate("/contest");
      // openUniqueCodePopup();
      setFirstTime(true);
      localStorage.setItem("isFirstVisit", true);
    }
    if (checkToken) {
      autoLoginUser(checkToken);
    }

    const handleBeforeUnload = () => {
      localStorage.removeItem("isFirstVisit");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const fetchLeaderBoardData = async () => {
    try {
      let reqData = {
        startDate: format(new Date(), "yyyy-MM-dd") || "",
        endDate: format(new Date(), "yyyy-MM-dd") || "",
        PageNumber: 1,
        PageSize: 2000,
      };
      const res = await getLeaderboardData(reqData);
      if (res.status === 200) {
        let data =
          res.data &&
          res.data.data &&
          res.data.data.winnerUsers &&
          Array.isArray(res.data.data.winnerUsers.data) &&
          res.data.data.winnerUsers.data.length
            ? res.data.data.winnerUsers.data
            : [];
        let userData =
          res.data && res.data.data && res.data.data.currentUser
            ? [
                {
                  ...res.data.data.currentUser,
                  rank:
                    res.data.data.currentUser.rank === 0
                      ? "-"
                      : res.data.data.currentUser.rank,
                },
              ]
            : [];
        dispatch(setLeaderboardData(data));
        dispatch(setLeaderboardUserData(userData));
      }
    } catch (err) {
      console.error("Error fetching leaderboard data:", err);
    }
  };

  const autoLoginUser = async (checkToken) => {
    try {
      const res = await refreshToken(checkToken, ipDetailsInfo.query);
      if (res.data && res.data.succeeded) {
        const data = res?.data?.data;
        let formData = {
          email: `${data.id}@gmail.com`,
          password: data.id,
        };
        if (data.newUser) {
          dispatch(displayContestTourTrigger(true));
          dispatch(showTourGuide({ showTourGuide: true }));
        }
        gluedinLogin(formData, true);
        localStorage.setItem("token", data.jwtToken);
        localStorage.setItem("userId", data.id);
        dispatch(loginSuccess(data));
        windowLayerPush(true);
        //GA event added for successfully otp verification
        window.dataLayer.push({ event: "CSBharat_OTP_Submit" });
        let langId = new URLSearchParams(location.search).get("lang");
        let langCode = ["en", "hi", "te", "or", "ba", "mr", "kn", "bho", "mai"];
        dispatch(setLanguageID(langId));
        dispatch(setLanguage(langCode[langId - 1]));
        navigate("/contest");
      }
    } catch (error) {
      // showApiErrors(error);
    }
  };

  return <>{<Home />}</>;
};

export default HomeIndex;
