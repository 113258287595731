import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { addressValidationSchema } from "../../../utils/validations";
import {
  getAddressByUserId,
  addUpdateAddress,
  deleteAddress,
  setAsDefaultAddress,
  getCityStateByPinCode,
  getProfile,
  getAllRewards,
  profileCompletePopup,
} from "../../../services";
import useLoader from "../../../utils/hooks/use-loader";
import toast from "react-hot-toast";
import AppModal from "../../modals";
import { useDispatch, useSelector } from "react-redux";
import {
  setAnimationShow,
  setDefaultAddress,
  setHomePageDetails,
} from "../../../store/slices/common-slice";
import useTranslation from "../../../utils/hooks/use-translation";
import { showApiErrors } from "../../../utils/helpers";
import { debounce } from "lodash";
import CommonFooter from "../../footer/CommonFooter";
import { getCDSPixelId } from "../../../utils/helpers";
import { setUser } from "../../../store/slices/auth-slice";
import useAuth from "../../../utils/hooks/use-auth";

const AddressForm = ({ source, handleClaimFormAddress = () => {} }) => {
  const { t } = useTranslation();
  let addressValidation = addressValidationSchema(t);
  const dispatch = useDispatch();
  const [openAddressModel, setAddressModel] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const { userDetails } = useAuth();
  const { toggleLoader } = useLoader();
  const initialValues = {
    AddressOne: "",
    AddressTwo: "",
    Near_Landmark: "",
    Pincode: "",
    State: "",
    City: "",
    Set_as_Default: "",
    shippingMobile: "",
  };
  const [intialData, setInitialData] = useState(initialValues);
  const [cityStateData, setCityStateData] = useState();
  const currentLanguageID = useSelector(
    (state) => state.common.currentLanguageID
  );
  const animationShow = useSelector((state) => state.common.animationShow);
  const selectedAddressCondition = Object.keys(selectedAddress).length !== 0;

  useEffect(() => {
    if (selectedAddressCondition === false) {
      setCityStateData();
    } else {
      const data = {
        StateName: selectedAddress ? selectedAddress.stateName : "",
        CityName: selectedAddress ? selectedAddress.cityName : "",
        City: selectedAddress ? selectedAddress.cityId : "",
        State: selectedAddress ? selectedAddress.stateId : "",
      };
      setCityStateData(data);
    }
  }, [selectedAddressCondition, selectedAddress]);

  const renderAddressInput = [
    {
      type: "text",
      name: "AddressOne",
      placeHolder: `${t.common.address_line1}*`,
    },
    {
      type: "text",
      name: "AddressTwo",
      placeHolder: t.common.address_line2,
    },
    {
      type: "text",
      name: "Near_Landmark",
      placeHolder: t.common.nearest_landmark,
    },
    {
      type: "tel",
      name: "shippingMobile",
      placeHolder: t.common.alternate_mobile_number,
    },
    {
      type: "text",
      inputType: "number",
      name: "Pincode",
      placeHolder: `${t.common.pincode}*`,
    },
    {
      //type: 'text',
      type: "select",
      name: "State",
      placeHolder: `${t.common.state}*`,
      //disabled: true,
      options: cityStateData
        ? [
            {
              label: cityStateData.state
                ? cityStateData.state
                : cityStateData?.StateName,
              value: cityStateData.stateId
                ? cityStateData.stateId
                : cityStateData.State,
            },
          ]
        : [],
    },
    {
      //type: 'text',
      type: "select",
      name: "City",
      placeHolder: `${t.common.city}*`,
      //disabled: true,
      options: cityStateData
        ? [
            {
              label: cityStateData.city
                ? cityStateData.city
                : cityStateData?.CityName,
              value: cityStateData.cityId
                ? cityStateData.cityId
                : cityStateData.City,
            },
          ]
        : [],
    },
    // {
    //   type: "tel",
    //   name: "shippingMobile",
    //   placeHolder: "Alternate Mobile Number",
    // },
    {
      type: "checkbox",
      name: "Set_as_Default",
      placeHolder: t.common.set_as_default,
      inputType: "checkBox",
      heading: t.common.set_as_default,
    },
  ];

  //Initiall call and load address list
  useEffect(() => {
    getAllAddressByUserId();
  }, []);

  //get all home page details
  const getAllAddressByUserId = async () => {
    toggleLoader(true);
    try {
      const res = await getAddressByUserId();
      if (res.status === 200 && res.data.succeeded) {
        let resdata = res.data && res.data.data;
        const defaultAddress =
          resdata &&
          Array.isArray(resdata) &&
          resdata.length !== 0 &&
          resdata.find((obj) => obj.isDefault);
        let addressId = resdata.find((obj) => obj.isDefault)?.id;
        handleClaimFormAddress(addressId);
        setAddressList(resdata);
        dispatch(setDefaultAddress(defaultAddress));
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  };

  //handle address submit
  const handleSubmit = async (values) => {
    const hfSessionKey = await getCDSPixelId();
    toggleLoader(true);
    try {
      let reqData = {
        id: values.id || 0,
        address1: values.AddressOne,
        address2: values.AddressTwo,
        NearestLandmark: values.Near_Landmark,
        pincode: values.Pincode,
        pinCodeId: values.pincodeId,
        cityId: values?.City,
        stateId: values?.State,
        isDefault:
          addressList.length === 0 ? true : values.Set_as_Default || false,
        isActive: true,
        nearestLandmark: values.Near_Landmark,
        shippingMobile: values.shippingMobile,
        hfSession: hfSessionKey || "",
      };
      const res = await addUpdateAddress(reqData);
      if (res.status === 201 || res.status === 204) {
        let profilePopup = await profileCompletePopup(userDetails.id);
        if (profilePopup.data.data !== "false") {
          getAllAddressByUserId();
          getAllRewardsData();
          getUserDetails();
          onClose();
          dispatch(setAnimationShow(true));
          setTimeout(() => {
            dispatch(setAnimationShow(false));
          }, 3000);
        } else {
          getAllAddressByUserId();
          getAllRewardsData();
          getUserDetails();
          onClose();
        }
      } else {
        // showApiErrors(res);
      }
    } catch (err) {
      // showApiErrors(err);
    } finally {
      toggleLoader(false);
    }
  };

  const getAllRewardsData = async () => {
    const hfSessionKey = await getCDSPixelId();
    try {
      const rewardsRes = await getAllRewards({
        languageId: currentLanguageID || "1",
        hfSession: hfSessionKey,
      });
      dispatch(
        setHomePageDetails({
          coinCount: rewardsRes?.data?.data?.currentBalance ?? 0,
        })
      );
    } catch (err) {
      // showApiErrors(err);
    }
  };

  // Update user detail to get profile completeion percentage
  const getUserDetails = async () => {
    toggleLoader(true);
    try {
      let reqData = {
        userId: userDetails?.id,
      };
      const res = await getProfile(reqData);
      if (res.status === 200) {
        let data = res?.data;
        dispatch(setUser(data));
      }
    } catch (err) {
      // showApiErrors(err);
    } finally {
      toggleLoader(false);
    }
  };

  const debouncedHandlePincodeChange = debounce(
    async (pincodeValue, setFieldValue) => {
      if (pincodeValue?.length === 6) {
        try {
          const res = await getCityStateByPinCode(pincodeValue);
          if (res.data && res.data.succeeded) {
            let data = res.data && res.data.data;
            setCityStateData(data);
            setFieldValue("City", data ? data.cityId : "");
            setFieldValue("State", data ? data.stateId : "");
            setFieldValue("cityId", data ? data.city : "");
            setFieldValue("stateId", data ? data.state : "");
            setFieldValue("pincodeId", data ? data.pincodeId : "");
            setFieldValue("Pincode", pincodeValue ? pincodeValue : "");
          }
        } catch (err) {
          // showApiErrors(err?.response?.data?.message);
        }
      }
    },
    300
  );

  //handle pin code changes city and state will populate based on pincode
  const handlePincodeChange = async (event, setFieldValue) => {
    const pincodeValue = event;
    debouncedHandlePincodeChange(pincodeValue, setFieldValue);
  };

  //render address form fields
  const renderAddressForm = () => {
    return (
      <Formik
        initialValues={intialData}
        validationSchema={addressValidation}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, touched, errors }) => (
          <Form autoComplete="off">
            <>
              <div className=" form-address" style={{ borderRadius: "1rem" }}>
                {renderAddressInput &&
                  renderAddressInput.map((field, indexing) => {
                    const isChecked = values && values?.Set_as_Default;
                    return (
                      <>
                        {
                          <div key={indexing} className="form-group">
                            {field.type === "checkbox" ? (
                              <>
                                <div className={"mt-4"}>
                                  <Field
                                    id={"check-box"}
                                    type="checkbox"
                                    name={`Set_as_Default`}
                                    className={`form-check-input ml-0 form-check ${
                                      touched[field.name] && errors[field.name]
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    checked={isChecked}
                                    placeHolder={field.placeHolder}
                                  />
                                  <label htmlFor={"check-box"}>
                                    {`${t.common.set_as_default}`}
                                  </label>
                                </div>
                              </>
                            ) : field.type === "select" ? (
                              <Field
                                as="select"
                                name={field.name}
                                className={`form-select form-custom-select bg-grey-2 ${
                                  touched[field.name] && errors[field.name]
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeHolder={field.placeHolder}
                                value={values[field.name]}
                                disabled={field.disabled ? true : false}
                              >
                                <option value="">{field.placeHolder}</option>
                                {field.options.map((el, optionIndex) => (
                                  <option key={optionIndex} value={el.value}>
                                    {el.label}
                                  </option>
                                ))}
                              </Field>
                            ) : (
                              <>
                                {field.label && (
                                  <label htmlFor={field.name}>
                                    {field.label}
                                  </label>
                                )}
                                <Field
                                  type={field.type}
                                  name={field.name}
                                  className={`form-control bg-grey-2 ${
                                    touched[field.name] && errors[field.name]
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  disabled={field.disabled}
                                  onChange={(e) => {
                                    setFieldValue(field.name, e.target.value);
                                    if (field.name === "Pincode") {
                                      handlePincodeChange(
                                        e.target.value,
                                        setFieldValue
                                      );
                                    }
                                  }}
                                  onInput={(e) => {
                                    if (
                                      field.name === "Pincode" ||
                                      field.name === "shippingMobile"
                                    ) {
                                      // Function to allow only numeric characters and restrict length to 10 for shippingMobile and 6 for Pincode
                                      e.target.value = e.target.value
                                        .replace(/[^0-9]/g, "")
                                        .slice(
                                          0,
                                          field.name === "shippingMobile"
                                            ? 10
                                            : 6
                                        );
                                    }
                                  }}
                                  inputMode={
                                    field.name === "Pincode" ||
                                    field.name === "shippingMobile"
                                      ? "numeric"
                                      : "text"
                                  } // Set inputMode to numeric for pincode and shippingMobile fields
                                  pattern={
                                    field.name === "Pincode" ||
                                    field.name === "shippingMobile"
                                      ? "[0-9]*"
                                      : undefined
                                  } // Allow only numeric characters for pincode and shippingMobile fields
                                  maxLength={
                                    field.name === "Pincode"
                                      ? 6
                                      : field.name === "shippingMobile"
                                      ? 10
                                      : undefined
                                  } // Limit maximum length to 6 for pincode and 10 for shippingMobile field
                                  placeHolder={field.placeHolder}
                                />
                              </>
                            )}
                            <ErrorMessage
                              name={field.name}
                              className="text-danger"
                              component="div"
                            />
                          </div>
                        }
                      </>
                    );
                  })}
                <button type="submit" className="btn btn-primary btn-block">
                  {t.common.save_address}
                </button>
              </div>
            </>
          </Form>
        )}
      </Formik>
    );
  };

  //handle delete address api call
  const deleteAddressApi = async (id) => {
    toggleLoader(true);
    try {
      let reqData = { id: id };
      const res = await deleteAddress(reqData);
      if (res.status === 200 && res.data.succeeded) {
        getAllAddressByUserId();
      } else {
        // toast.error(res.data && res.data.message);
      }
    } catch (err) {
      // showApiErrors(err);
    } finally {
      toggleLoader(false);
    }
  };

  //handle checkbox change and set as default action api call
  const handleCheckBoxChange = async (e, id) => {
    toggleLoader(true);
    const hfSessionKey = await getCDSPixelId();
    try {
      let reqData = { id: id, hfSession: hfSessionKey || "" };
      const res = await setAsDefaultAddress(reqData);
      if (res.status === 200 && res.data.succeeded) {
        getAllAddressByUserId();
      } else {
        toast.error(res.data && res.data.message);
      }
    } catch (err) {
      // showApiErrors(err);
    } finally {
      toggleLoader(false);
    }
  };

  //render address list which is already added
  const renderAddress = () => {
    if (addressList && addressList.length !== 0) {
      return addressList.map((el, i) => {
        const isChecked = el && el?.isDefault;
        return (
          <div
            key={i}
            className={
              source === "profile"
                ? "col-12 bg-light address-font-style profile-address claim-address mx-auto  px-0"
                : "col-12 bg-light py-6  mt-4 claim-address px-0 px-md-2"
            }
          >
            <img
              src={"/assets/img/icons/profile-edit.svg"}
              alt={""}
              onClick={() => {
                const data = {
                  AddressOne: el ? el.address1 : "",
                  AddressTwo: el ? el.address2 : "",
                  Near_Landmark: el ? el.nearestLandmark : "",
                  Pincode: el ? el.pincode : "",
                  StateName: el ? el.stateName : "",
                  CityName: el ? el.cityName : "",
                  Set_as_Default: el ? el.isDefault : "",
                  City: el ? el.cityId : "",
                  State: el ? el.stateId : "",
                  pincodeId: el ? el.pinCodeId : "",
                  id: el ? el.id : "",
                  shippingMobile: el.shippingMobile,
                };
                setSelectedAddress(el);
                setInitialData(data);
                setAddressModel(true);
              }}
              className={source === "profile" ? "float-right edit" : ""}
            />
            {source === "claim" && (
              <input
                id={`claim_address`}
                type="radio"
                name={`Claim_Address`}
                className=" ml-0 float-right"
                // checked={isChecked}
              />
            )}
            <h3 className="address-font-style">
              {`${el.address1 ? el.address1 + ", " : ""}${
                el.address2 ? el.address2 + ", " : ""
              }${el.nearestLandmark ? el.nearestLandmark + ", " : ""}${
                el.cityName ? el.cityName + ", " : ""
              }${el.stateName ? el.stateName + " " : ""}${
                el.pincode ? el.pincode : ""
              }`}
            </h3>
            {source === "profile" && (
              <div className="text-dark mobile-no-info pl-0 pt-2">
                {el.shippingMobile && `+91-${el.shippingMobile}`}
              </div>
            )}
            {source === "profile" && (
              <div className="mt-4 d-flex justify-content-between align-items-center">
                <Field
                  id={`Set_as_Default${i}`}
                  type="radio"
                  name={`Set_as_Default`}
                  className="form-check-input ml-0 form-check"
                  onChange={(e) => {
                    handleCheckBoxChange(e, el.id);
                  }}
                  checked={isChecked}
                />
                <label
                  className="default-address-label"
                  htmlFor={`Set_as_Default${i}`}
                >
                  {t.common.set_as_default}
                </label>
                <img
                  src={"/assets/img/icons/delete-icon.svg"}
                  alt={""}
                  onClick={() => {
                    let id = el.id;
                    deleteAddressApi(id);
                  }}
                  className=""
                />
              </div>
            )}
            {source === "profile" && i < addressList.length - 1 && (
              <div className="address-divider mt-4 "></div>
            )}
          </div>
        );
      });
    }
  };

  //handle address model close
  const onClose = () => {
    setAddressModel(false);
    setInitialData(initialValues);
    setSelectedAddress("");
  };

  return (
    <>
      <div className="card profile-address address-card col-12 mx-auto ">
        <div className="col-sm-12  d-flex justify-content-between align-items-center  mx-auto px-0 mb-2 claim">
          <h5 className=" address-tag">{t.common.address}</h5>
          <button
            type="button"
            className={`form-add-btn address-add-btn border-0 bg-light  px-0 ${
              addressList && addressList.length === 3 ? "btn-disabled" : ""
            }`}
            onClick={() => {
              setAddressModel(true);
            }}
            disabled={addressList && addressList.length === 3}
          >
            + {t.common.add}
          </button>
        </div>
        <div className="row  mx-auto  px-0 profile-address bg-light w-100">
          {renderAddress()}
        </div>{" "}
      </div>
      {openAddressModel && (
        <AppModal
          heading={
            selectedAddress ? t.common.update_address : t.common.add_address
          }
          show={openAddressModel}
          headerClass={`px-5 pt-6`}
          onClose={onClose}
          bodyClass="pb-6 pt-md-6 pt-1"
          animationShow={animationShow}
        >
          {renderAddressForm()}
          <CommonFooter />
        </AppModal>
      )}
    </>
  );
};

export default AddressForm;
