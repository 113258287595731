import api from "./api";

export const getReels = async (data) => {
  const response = await api.get("/refresh-rings/get-reels", {
    params: {
      label: data.label,
    },
  });

  if (!response?.data?.success) throw new Error("Failed to fetch reels");

  return response.data;
};

export const reactReel = async (data) => {
  const response = await api.post("/refresh-rings/react-reel", {
    jokeID: data.videoId,
    reaction: data.react,
  });

  if (!response?.data?.success) throw new Error("Failed to react on reel");

  return response.data;
};

export const viewReel = async (data) => {
  const response = await api.post("/refresh-rings/view-reel", {
    jokeID: data.videoId,
  });

  if (!response?.data?.success) throw new Error("Failed to call view reel");

  return response.data;
};
