import toast from "react-hot-toast";

let is401ErrorShown = false;

//mobile number validation
export const handleKeyPressMobileNumber = (event) => {
  const currentValue = event.target.value;
  // Remove any non-digit characters and limit the value to a maximum of 10 digits
  const filteredValue = currentValue.replace(/\D/g, "").slice(0, 10);

  // Check if the filtered value is '00000000' and handle accordingly
  if (/^0+$/.test(filteredValue)) {
    event.preventDefault();
    console.error("Invalid mobile number");
    return;
  }

  // Update the input value with the filtered value
  event.target.value = filteredValue;
};

export const handleErrorMessages = (res, setFieldError, key) => {
  let message = res.data && res.data.message;
  setFieldError(key, message);
};

export const showApiErrors = (res) => {
  const status = res?.StatusCode || res?.status;
  let message =
    res?.response?.data?.message ||
    res?.data?.message ||
    res?.message ||
    "Something went wrong";
  if (status !== 200) {
    if (!is401ErrorShown) {
      is401ErrorShown = true;
      toast.error(message || "Unauthorized access");
    }
  } else {
    toast.error(message);
  }
  setTimeout(() => (is401ErrorShown = false), 8000);
};

export const returnMobileNumber = (data) => {
  if (data.alternateMobile) {
    return "+91 - " + data.mobileNo + ", +91 - " + data.alternateMobile;
  } else {
    return "+91 - " + data.mobileNo;
  }
};

export const formatLargeNumber = (count) => {
  if (count < 1000) {
    return count.toString();
  } else if (count < 1000000) {
    return (count / 1000).toFixed(1) + "K";
  } else {
    return (count / 1000000).toFixed(1) + "M";
  }
};

export const convertKToNumeric = (kValue) => {
  if (typeof kValue === "string" && kValue.match(/(\d+(\.\d+)?)(K|M|B)?/)) {
    let match = kValue.match(/(\d+(\.\d+)?)(K|M|B)?/);
    let numericValue = parseFloat(match[1]);
    let modifier = match[3];
    if (modifier) {
      if (modifier === "K") {
        numericValue *= 1000;
      } else if (modifier === "M") {
        numericValue *= 1000000;
      } else if (modifier === "B") {
        numericValue *= 1000000000;
      }
    }
    return numericValue;
  }
  return null; // Return null for invalid input
};

export const getCDSPixelId = async () => {
  //cookieStore doesn't work for safari and firefox
  try {
    const fetchPixelId = await window?.cookieStore?.get("__cds_pixel_id");
    if (fetchPixelId) {
      return fetchPixelId?.value;
    } else {
      return "";
    }
  } catch (error) {
    // showApiErrors(error);
  }
};

export const CounterFloodLight = (sendToId, customVariable = null) => {
  let sendToObj = {
    allow_custom_scripts: true,
    send_to: sendToId,
  };
  if (customVariable) {
    sendToObj = { ...sendToObj, ...customVariable };
  }
  window.gtag("event", "conversion", sendToObj);
};

export const MetaPixel = (event) => {
  window.fbq("track", event);
};

export const TTDPixel = (pixelId) => {
  function initTTDPixel() {
    if (typeof window.TTDUniversalPixelApi === "function") {
      var universalPixelApi = new window.TTDUniversalPixelApi();
      universalPixelApi.init(
        "kyjrhq2",
        [`${pixelId}`],
        "https://insight.adsrvr.org/track/up"
      );
    }
  }

  if (typeof window.ttd_dom_ready === "function") {
    window.ttd_dom_ready(initTTDPixel);
  } else {
    // Handle the case when ttd_dom_ready is not available or not defined.
    // You might want to wait or find an alternative way to ensure the required function is available.
  }
};

export const handleInputLimitedLength = (event, minLength, maxLength) => {
  const inputValue = event.target.value;
  // Limit the input value to the maximum length
  let limitedValue = inputValue.slice(0, maxLength);
  // Enforce minimum length
  if (limitedValue.length < minLength) {
    limitedValue = inputValue.slice(0, minLength);
  }
  // Update the input value with the limited value
  event.target.value = limitedValue;
};
