import React, { lazy, useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { createValidationSchema } from "../../../utils/validations";
import CommonSection from "../CommonSection";
import SectionHeader from "../SectionHeader";
import { useNavigate, useLocation } from "react-router";
import toast from "react-hot-toast";
import useAuth from "../../../utils/hooks/use-auth";
import useTranslation from "../../../utils/hooks/use-translation";
import UploadForm from "./UploadForm";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getJokeGenreID } from "../../../services";
import { useDispatch, useSelector } from "react-redux";
import { appendObjectToFormData, languages } from "../../../utils/constants";
import { TTDPixel, showApiErrors } from "../../../utils/helpers";
import { ugcUploadService } from "../../../services/ugc";
import { setJokeSubmitSuccess } from "../../../store/slices/common-slice";
import { setGenereID } from "../../../store/slices/common-slice";
import useBottleLoader from "../../../utils/hooks/use-bottle-loader";

const MoodPickerSlider = lazy(() => import("../Carousel"));

const UgcUpload = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toggleBottleLoader } = useBottleLoader();
  const { userDetails } = useAuth();
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState({ genreId: "" });
  const [textValue, setTextValue] = useState("");
  const [genreData, setGenreData] = useState([]);
  const [jokeSubmitSuccessPopup, setJokeSubmitSucessPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState({
    format: "",
    file: null,
  });
  let isPassportFieldShow = state && state.isPassportFieldShow;
  let isPanFieldShow = state && state.isPanFieldShow;
  const currentLanguageID = useSelector(
    (state) => state.common.currentLanguageID
  );

  const initialValues = {
    JokeTitle: "",
    Name: userDetails ? userDetails.name : "",
    mobile: userDetails ? userDetails.mobileNo : "",
    Email: userDetails ? userDetails.email : "",
    PassNo: "",
    Govid: "",
    Format: "",
    Title: "",
    Category: "",
    termsAgreed: false,
    Language: "",
  };

  useEffect(() => {
    getJokeGenreID({ languageId: currentLanguageID || "1" }).then(
      (genreData) => {
        setGenreData(genreData.data.data);
      }
    );
    dispatch(setGenereID(0));
    setSelectedCategory({ genreId: "" });
  }, []);

  //empty error message on tab change
  useEffect(() => {
    setErrorMessage("");
  }, [selectedTab]);

  //empty error message on file uploaded
  useEffect(() => {
    if (uploadedFiles.format && uploadedFiles.file) {
      setErrorMessage("");
    }
  }, [uploadedFiles.format, uploadedFiles.file]);

  const tabsData = [
    {
      imgSrc: "./assets/img/ugcupload/upload-img.svg",
      text: t.common.image,
      uploadForm: <UploadForm activeTab={`${t.common.image}`} />,
      format: "Image",
    },
    {
      imgSrc: "./assets/img/ugcupload/upload-text.svg ",
      text: t.common.text,
      uploadForm: <UploadForm activeTab={`${t.common.text}`} />,
      format: "Text",
    },
    {
      imgSrc: "./assets/img/ugcupload/upload-audio.svg",
      text: t.common.audio,
      uploadForm: <UploadForm activeTab={`${t.common.audio}`} />,
      format: "Audio",
    },
    {
      imgSrc: "./assets/img/ugcupload/upload-video.svg",
      text: t.common.video,
      uploadForm: <UploadForm activeTab={`${t.common.video}`} />,
      format: "Video",
    },
  ];

  const handleTabClick = (tabIndex) => {
    setSelectedTab(tabIndex);
  };

  const renderInputs = [
    {
      type: "select",
      name: "Language",
      label: `${t.common.select_language}*`,
      placeholder: t.common.select_language,
      options: languages.map((lang) => lang?.langScript),
    },
    {
      type: "radio",
      name: "Format",
      label: `${t.common.select_format}* `,
    },
    {
      type: "text",
      name: "Title",
      label: `${t.common.title}*`,
      placeholder: t.common.joke_title,
      maxLength: 30,
    },
    {
      type: "slider",
      name: "Category",
      label: `${t.common.category}`,
      categories: [
        {
          text: t.common.daily_humour,
          imgSrc: "./assets/img/icons/category-type/daily-humour.svg ",
        },
        {
          text: t.common.dating,
          imgSrc: "./assets/img/icons/category-type/dating.svg ",
        },
        {
          text: t.common.family,
          imgSrc: "./assets/img/icons/category-type/family.svg",
        },
        {
          text: t.common.finance,
          imgSrc: "./assets/img/icons/category-type/finance.svg",
        },
        {
          imgSrc: "./assets/img/icons/category-type/food.svg",
          text: t.common.food,
        },
        {
          imgSrc: "./assets/img/icons/category-type/friends.svg",
          text: t.common.friends,
        },
        {
          text: t.common.internet,
          imgSrc: "./assets/img/icons/category-type/internet.svg",
        },
        {
          text: t.common.movies,
          imgSrc: "./assets/img/icons/category-type/movies.svg",
        },
        {
          text: t.common.office,
          imgSrc: "./assets/img/icons/category-type/office.svg",
        },
        {
          text: t.common.ott,
          imgSrc: "./assets/img/icons/category-type/ott.svg",
        },
        {
          text: t.common.pollution,
          imgSrc: "./assets/img/icons/category-type/pollution.svg",
        },
        {
          text: t.common.travel,
          imgSrc: "./assets/img/icons/category-type/travel.svg",
        },
        // {
        //   text: "Traffic",
        //   imgSrc: "./assets/img/icons/category-type/traffic-signal.svg",
        // },
      ],
    },
  ];

  const isDesktop = window.innerWidth >= 992;

  const handleSubmit = async (values) => {
    toggleBottleLoader(true);

    if (uploadedFiles.file && [0, 2, 3].includes(selectedTab)) {
      let fileName = uploadedFiles.file.name;
      let extension = fileName.split(".").length > 2;
      console.log(extension, fileName.split("."));
      if (extension) {
        toast.error("Please upload a file for the selected format");
        setUploadedFiles({
          format: "",
          file: null,
        });
        setIsFileUploaded(false);
        toggleBottleLoader(false);
        return;
      }
    }

    if (!uploadedFiles.file && selectedTab === 0) {
      setErrorMessage(`${t.validation_messages.required.image}`);
      setUploadedFiles({
        format: "",
        file: null,
      });
      setIsFileUploaded(false);
      toggleBottleLoader(false);
      return;
    } else if (!uploadedFiles.file && selectedTab === 1) {
      setErrorMessage(`${t.validation_messages.required.text}`);
      setUploadedFiles({
        format: "",
        file: null,
      });
      setIsFileUploaded(false);
      toggleBottleLoader(false);
      return;
    } else if (
      selectedTab === 2 &&
      !["audio/wav", "audio/mp3", "audio/mpeg"].includes(
        uploadedFiles?.file?.type
      )
    ) {
      toast.error("Please upload a file for the selected format");
      setUploadedFiles({
        format: "",
        file: null,
      });
      setIsFileUploaded(false);
      toggleBottleLoader(false);
      return;
    }
    // else if (!uploadedFiles.file && selectedTab == 3) {
    //   setErrorMessage(`${t.validation_messages.required.video}`);
    // }
    else if (!uploadedFiles.file && selectedTab === 3) {
      setErrorMessage(`${t.validation_messages.required.video}`);

      setErrorMessage("Please Submit a Video in under Video upload box.");
      setUploadedFiles({
        format: "",
        file: null,
      });
      setIsFileUploaded(false);
      toggleBottleLoader(false);
      // if (!uploadedFiles.file && selectedTab !== 1) {
      //   toast.error("Please upload a file for the selected format");
      //   toggleLoader(false);
      //   return;
      // }
      // if (!uploadedFiles.file && selectedTab == 1) {
      //   toast.error("Please add a text for selected tab");
      //   toggleLoader(false);
      //   return;
      // }
    } else {
      try {
        const formData = new FormData();
        appendObjectToFormData(formData, {
          LanguageId: values?.Language
            ? languages.find((lang) => lang.langScript === values.Language)?.id
            : "",
          Format: uploadedFiles.format,
          Title: values.Title,
          JokeGenreId: selectedCategory.genreId,
          FileToUpload: uploadedFiles?.file,
        });
        const res = await ugcUploadService(formData);
        if (res.data && res.data.succeeded) {
          TTDPixel("375fy0h");
          setJokeSubmitSucessPopup(true);
          let event = "";
          if (selectedTab === 0) {
            event = "Sprite24_Image_JokeSubmit";
          } else if (selectedTab === 1) {
            event = "Sprite24_Text_JokeSubmit";
          } else if (selectedTab === 2) {
            event = "Sprite24_Audio_JokeSubmit";
          } else {
            event = "Sprite24_Video_JokeSubmit";
          }
          window.dataLayer.push({ event: event });
          navigateTo("home");
        } else {
          toast.error("Retry again");
          // showApiErrors(res);
        }
      } catch (err) {
        if (
          err?.response?.data?.message === "Max limit exhausted for UGC upload"
        ) {
          toast.error(err?.response?.data?.message);
        }
      } finally {
        toggleBottleLoader(false);
      }
    }

    // Rest of your submission logic
  };

  const headerContent = {
    title: t.header.submit_your_jokes,
    subTitle: t.header.hit_funniest_joke,
  };

  const claimFormValidationSchema = createValidationSchema(
    isPassportFieldShow,
    isPanFieldShow,
    t
  );
  const handleCategorySelect = (category) => {
    setSelectedCategory({ genreId: category });
  };

  // const handleText = (event, tab) => {
  //   const newValue = event.target.value;
  //   setTextValue(newValue);
  //   const blob = new Blob([newValue], { type: "text/plain" });
  //   const fileName = "text_file.txt";
  //   const file = new File([blob], fileName, { type: "text/plain" });

  //   setUploadedFiles({
  //     format: tab,
  //     file: file,
  //   });
  // };

  // const handleFileChange = (event, activeTab) => {
  //   const tab = activeTab;
  //   const selectedFile = event?.target?.files?.[0];

  //   setUploadedFiles({
  //     format: tab,
  //     file: selectedFile,
  //   });
  // };

  const navigateTo = (route) => {
    dispatch(
      setJokeSubmitSuccess({
        state: true,
        message: "You did good, we'll take it from here. Ping you in 14 days!",
      })
    );
    navigate("/");
  };

  return (
    <div className="upload-form  col-12 mx-auto">
      <CommonSection
        headerContent={headerContent}
        headerType={3}
        containerClass="pt-6 mt-md-15 "
        sectionClass=" px-0"
      >
        {isDesktop && (
          <SectionHeader
            {...headerContent}
            headerClass={`pt-6 pb-5 pt-md-10 pb-md-6 text-md-center text-left`}
          />
        )}

        <Formik
          initialValues={initialValues}
          validationSchema={claimFormValidationSchema}
          onSubmit={handleSubmit}
          className=""
        >
          {({ touched, errors, values }) => (
            <Form
              id={"form_submit"}
              autoComplete="off"
              className="form-upload row justify-content-center mt-12 mt-lg-10  "
            >
              {renderInputs &&
                renderInputs?.map((field, i) => (
                  <div
                    key={i}
                    className={`form-group ${
                      field.name === "Category"
                        ? "col-12 px-0 error "
                        : "col-sm-12 px-0  error"
                    }`}
                  >
                    <label htmlFor={field.name}>{field.label}</label>
                    {field.type === "select" ? (
                      <Field
                        as={field.type}
                        name={field.name}
                        className={`form-control form-select form-custom-select  ${
                          touched[field.name] && errors[field.name]
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        <option value="" label={field.placeholder} />
                        {field.options.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </Field>
                    ) : field.type === "slider" ? (
                      <div className="form-category-slider">
                        <div className="row " id="pick_mood">
                          <div className={`col-12 p-0`}>
                            <MoodPickerSlider
                              data={genreData}
                              wrapperClassName="btn-category"
                              cardType={"moodPicker"}
                              SliderContainerClass={`px-4 px-md-4`}
                              sectionClass={"mt-md-8 mb-md-8 mb-0"}
                              sliderType={7}
                              isHeader={false}
                              handleCategorySelect={handleCategorySelect}
                            />
                          </div>
                        </div>
                      </div>
                    ) : field.type === "radio" ? (
                      <div className=" px-0 ">
                        {/* Wrap tabs in col-12 */}
                        <div className="mb-4  d-flex justify-content-around col-md-9 form-upload-format px-0">
                          {tabsData.map((tab, index) => (
                            <div
                              key={index}
                              onClick={() => handleTabClick(index)}
                              className={`btn-upload ${
                                selectedTab === index ? "active" : ""
                              }`}
                            >
                              <img src={tab.imgSrc} alt={`Tab ${index + 1}`} />
                              <span>{tab.text}</span>
                            </div>
                          ))}
                        </div>

                        <div>
                          {/* Render content based on the selected tab */}
                          <UploadForm
                            activeTab={tabsData[selectedTab]?.text}
                            activeTabFormat={tabsData[selectedTab]?.format}
                            uploadedFiles={uploadedFiles}
                            textValue={textValue}
                            setTextValue={setTextValue}
                            setUploadedFiles={setUploadedFiles}
                            isFileUploaded={isFileUploaded}
                            setIsFileUploaded={setIsFileUploaded}
                          />
                          {errorMessage && (
                            <span className="text-danger my-0">
                              {errorMessage}
                            </span>
                          )}
                        </div>
                      </div>
                    ) : (
                      <>
                        <Field
                          type={field.type}
                          name={field.name}
                          className={`form-control ${
                            touched[field.name] && errors[field.name]
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder={field.placeholder}
                          disabled={field.disabled}
                          maxLength={field.maxLength}
                        />
                        <div className="text-muted text-md-center text-left">
                          {t.common.max_limit}
                        </div>
                      </>
                    )}
                    {field.mutedText && (
                      <small className="form-text text-muted">
                        {field.mutedText}
                      </small>
                    )}
                    <ErrorMessage
                      name={field.name}
                      className="text-danger"
                      component="div"
                    />
                  </div>
                ))}
              {/* Checkbox for agreeing to terms */}
              <div className="form-group px-4 mt-md-4 mt-2 form-category-checkbox  ">
                <Field
                  type="checkbox"
                  id="termsAgreed"
                  name="termsAgreed"
                  checked={values.termsAgreed}
                  // onChange={(e) => setTermsAgreed(e.target.checked)}
                  className={`form-check-input ${
                    touched.termsAgreed && errors.termsAgreed
                      ? "is-invalid"
                      : ""
                  }`}
                />

                <label
                  htmlFor="termsAgreed"
                  className="form-check-label ml-2 text-left"
                >
                  {t.auth.upload_terms_conditions}
                </label>
                <ErrorMessage
                  name="termsAgreed"
                  className="text-danger"
                  component="div"
                />
              </div>
              {/* Submit button */}
              <button
                type="submit"
                className="btn btn-primary btn-block col-md-5 btn-upload-submit"
              >
                {t.common.submit}
              </button>
            </Form>
          )}
        </Formik>
        <p className="text-center pt-2 h4 mb-2">
          * {t.common.all_fields_mandatory}
        </p>
      </CommonSection>
    </div>
  );
};

export default UgcUpload;
