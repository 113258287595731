import React from "react";
import { Container } from "react-bootstrap";

export default function Banner({ videoURL = "cool-boy-banner-en.mp4" }) {
  return (
    <Container fluid>
      <div
        style={{
          position: "relative",
          overflow: "hidden",
          borderRadius: "16px",
          backgroundColor: "grey",
          marginTop: "2rem",
        }}
      >
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            display: "block",
          }}
        >
          <source
            src={`${process.env.REACT_APP_IMAGE_BASE_URL}/Banners/Desktop/${videoURL}`}
            type="video/mp4"
          />
          Your browser does not support the video tag
        </video>
      </div>
    </Container>
  );
}
