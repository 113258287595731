import { useEffect, useState } from "react";

const useCheckTokenExpiry = (checkingAuth, isTokenValid) => {
  const [isTokenExpired, setIsTokenExpired] = useState(true);

  useEffect(() => {
    if (checkingAuth) return; // Don't run the effect if checkingAuth is true

    if (!isTokenValid) return setIsTokenExpired(false);

    let timeout = null;

    const checkTokenExpiry = () => {
      const expiresOn = localStorage.getItem("expiresOn");
      const refreshToken = localStorage.getItem("refresh");

      console.log("Checking token expiry...");
      console.log("Stored expiresOn:", expiresOn);
      console.log("Stored refresh token:", refreshToken);

      if (!expiresOn || !refreshToken) {
        console.warn("Missing expiresOn or refresh token");
        setIsTokenExpired(false);
        return;
      }

      const expiryTime = new Date(expiresOn).getTime();
      const currentTime = Date.now();

      if (isNaN(expiryTime)) {
        console.error("Invalid expiresOn value:", expiresOn);
        setIsTokenExpired(false);
        return;
      }

      const timeLeft = expiryTime - currentTime;

      console.log("Current time:", new Date(currentTime).toISOString());
      console.log("Expiry time:", new Date(expiryTime).toISOString());
      console.log("Time left (ms):", timeLeft);

      if (timeLeft <= 0) {
        console.warn("Token expired immediately");
        setIsTokenExpired(false);
        localStorage.removeItem("expiresOn");
        localStorage.removeItem("refresh");
        return;
      }

      // Set timeout to expire token exactly at the right time
      timeout = setTimeout(() => {
        console.warn("Token expired after timeout");
        setIsTokenExpired(false);
        localStorage.removeItem("expiresOn");
        localStorage.removeItem("refresh");
      }, timeLeft);
    };

    checkTokenExpiry();

    // Listen for localStorage changes
    const handleStorageChange = (event) => {
      if (event.key === "expiresOn" || event.key === "refresh") {
        console.log(`Storage change detected for: ${event.key}`);
        checkTokenExpiry();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      if (timeout) clearTimeout(timeout);
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [checkingAuth]);

  return isTokenExpired;
};

export default useCheckTokenExpiry;
