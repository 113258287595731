import { useState, useRef, useEffect } from "react";
import {
  HiMiniArrowDown,
  HiMiniArrowUp,
  HiOutlinePause,
  HiOutlinePlay,
  HiOutlineSpeakerWave,
  HiOutlineSpeakerXMark,
} from "react-icons/hi2";
import { useViewport } from "../../utils/context/viewport-context";
import gluedin from "gluedin";
import { useDispatch, useSelector } from "react-redux";
import { getReels, reactReel, viewReel } from "../services/creatorCollabs";
import { useLocation } from "react-router-dom";
import "../Refresh-Rings/shorts.scss";
import { getLanguageCode } from "../utils/utils";
const CreatorCollabs = () => {
  const [fetchingReels, setFetchingReels] = useState(true);
  const [videos, setVideos] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const currentIndexRef = useRef(0);
  const videoRefs = useRef([]); // Store video elements
  const watchedVideos = useRef(new Set());

  // const navigate = useNavigate();
  const location = useLocation();

  const ipDetailsInfo = useSelector((state) => state?.common?.ipDetailsInfo);

  useEffect(() => {
    const rootRR = document.querySelector("#root");
    if (rootRR) {
      rootRR.classList.add("refresh_ring_main");
    }
    const header = document.querySelector(".header");
    if (header) {
      document.documentElement.style.setProperty(
        "--headerHeight",
        `${header.offsetHeight}px`
      );
    }
    const footer = document.querySelector(".footer");
    if (footer) {
      document.documentElement.style.setProperty(
        "--footerHeight",
        `${footer.offsetHeight}px`
      );
    }

    return () => {
      rootRR.classList.remove("refresh_ring_main");
    };
  }, []);

  useEffect(() => {
    const langId = new URLSearchParams(location.search).get("lang");
    const langCode = getLanguageCode(langId);

    const fetchReels = async (data) => {
      try {
        const res = await getReels(data);
        if (res.success) {
          setVideos(res.data);
        }
      } catch (error) {
        // showApiErrors(error);
      } finally {
        setFetchingReels(false);
      }
    };
    fetchReels({
      label: langCode,
    });
  }, []);

  // Auto-play only the active video & pause others
  useEffect(() => {
    const currentVideo = videoRefs.current[currentIndex];

    if (currentVideo) {
      // currentVideo.muted = currentIndex === 0; // Mute only the first video
      currentVideo.currentTime = 0;
      const playPromise = currentVideo.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            setIsPlaying(true);
          })
          .catch((error) => {
            console.error("Autoplay prevented:", error);
          });
      }
    }

    // Pause all other videos
    videoRefs.current.forEach((video, index) => {
      if (video && index !== currentIndex) {
        video.pause();
        //video.muted = false; // Ensure subsequent videos are unmuted
      }
    });
  }, [currentIndex]);

  // Detect active video based on scrolling
  useEffect(() => {
    if (fetchingReels) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = videoRefs.current.findIndex(
            (el) => el === entry.target
          );

          if (index !== -1) {
            if (entry.isIntersecting) {
              // If the video is in view, set it as the current index
              setCurrentIndex(index);
              currentIndexRef.current = index;
              videoRefs.current[index]?.play();
            } else {
              // If the video goes out of view, pause it
              videoRefs.current[index]?.pause();
            }
          }
        });
      },
      { threshold: 0.7 }
    );

    videoRefs.current.forEach((video) => {
      if (video) observer.observe(video);
    });

    return () => observer.disconnect();
  }, [videos, fetchingReels]);

  // Scroll to a specific video index
  const scrollToIndex = (index) => {
    setTimeout(() => {
      const videoElement = videoRefs.current[index];
      if (videoElement) {
        videoElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }, 100);
  };

  const handleUpClick = () => {
    if (currentIndexRef.current > 0) {
      const newIndex = currentIndexRef.current - 1;
      currentIndexRef.current = newIndex;
      setCurrentIndex(newIndex);
      scrollToIndex(newIndex);
    }
  };

  const handleDownClick = () => {
    if (currentIndexRef.current < videos.length - 1) {
      const newIndex = currentIndexRef.current + 1;
      currentIndexRef.current = newIndex;
      setCurrentIndex(newIndex);
      scrollToIndex(newIndex);
    }
  };

  const handlePlayPause = () => {
    const videoElement = videoRefs.current[currentIndexRef.current];
    if (videoElement) {
      if (videoElement.paused) {
        videoElement.play();
        setIsPlaying(true);
      } else {
        videoElement.pause();
        setIsPlaying(false);
      }
    }
  };

  const handleMuteUnmute = () => {
    const videoElement = videoRefs.current[currentIndexRef.current];
    if (videoElement) {
      videoElement.muted = !videoElement.muted;
      setIsMuted(videoElement.muted);
    }
  };

  const handleReact = async (react, videoId) => {
    setVideos((prev) => {
      return prev.map((video) => {
        if (video.id === videoId) {
          const updatedReactions = video.reactions || {};
          const currentReactionCount = updatedReactions[react] || 0;
          return {
            ...video,
            userReaction: react,
            reactions: {
              ...updatedReactions,
              [react]: currentReactionCount + 1,
            },
          };
        }
        return video;
      });
    });
    const activityTimelineModule = new gluedin.GluedInActivityTimeline();
    await activityTimelineModule.activityTimelineReact({
      assetId: videoId,
      reactionType: react,
      metakeys: {
        country: ipDetailsInfo && ipDetailsInfo.country,
        country_code: ipDetailsInfo && ipDetailsInfo.countryCode,
        region: ipDetailsInfo && ipDetailsInfo.regionName,
        region_code: ipDetailsInfo && ipDetailsInfo.region,
        city: ipDetailsInfo && ipDetailsInfo.city,
      },
    });
    await reactReel({
      videoId,
      react,
    });
  };

  const handleTimeUpdate = (event, videoId) => {
    const video = event.currentTarget;
    if (video.currentTime >= 2 && !watchedVideos.current.has(videoId)) {
      watchedVideos.current.add(videoId);
      console.log(`Video played for 2 seconds: ${videoId}`);
      setVideos((prev) => {
        return prev.map((video) => {
          if (video.id === videoId) {
            const currentViews = video.viewCount || 0;
            return {
              ...video,
              viewCount: currentViews + 1,
            };
          }
          return video;
        });
      });
      videoWatched(videoId);
    }
  };

  const videoWatched = (videoId) => {
    const activityTimelineModule = new gluedin.GluedInActivityTimeline();
    activityTimelineModule.activityTimelineView({
      assetId: videoId,
    });
    viewReel({
      videoId,
    });
  };

  if (fetchingReels)
    return (
      <div
        className="firstLoading"
        style={{
          // position: "fixed",
          width: "100%",
          height: "100dvh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // top: 0,
          // left: 0,
          fontSize: "2rem",
          // backgroundImage: "url('/assets/img/refreshRigns/refreshRingsBg.png')",
          // backgroundColor: "white",
          zIndex: 1,
        }}
      >
        <div className="loaderImg">
          <div className="loader"></div>
          Loading...
        </div>
      </div>
    );

  return (
    <>
      <div className="rrTextMain">
        {/* <img
          className="rrTextMainImg"
          src={"/assets/img/refreshRigns/rr_header_text.svg"}
          alt="rrTextMain"
        /> */}

        <div className="rrTextTitle">Creator Collabs</div>
        <div className="rrTextSubTitle">The Ultimate Comedy Vault.</div>
      </div>
      <div
        className="mainSlideWrap"
        style={{
          position: "relative",
          width: "100%",
          overflowY: "scroll",
          scrollBehavior: "smooth",
          scrollSnapType: "y mandatory",
          scrollSnapStop: "always",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          scrollbarWidth: "none",
        }}
      >
        {videos.length > 0 &&
          videos?.map((video, index) => (
            <div
              className="slideBlock"
              key={index}
              style={{
                position: "relative",
                // height: "100%",
                scrollSnapAlign: "center",
                // aspectRatio: "9/16",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // outline: currentIndex === index ? "2px solid green" : "none",
              }}
            >
              {/* Controls */}
              <Controls
                isPlaying={isPlaying}
                isMuted={isMuted}
                onPlay={handlePlayPause}
                onMute={handleMuteUnmute}
              />

              {/* Video Element */}
              <VideoComponent
                video={video}
                index={index}
                currentIndex={currentIndex}
                videoRefs={videoRefs}
                isMuted={isMuted}
                handleTimeUpdate={handleTimeUpdate}
              />

              {/* <div className="videoLoader">
                <div className="videoLoaderWrap">
                  <div className="loaderImg">
                    <div className="loader"></div>
                  </div>
                  <div className="loadingText">Loading...</div>
                </div>
              </div> */}

              {/* Reaction Stats */}
              <ReactionStats video={video} handleReact={handleReact} />
            </div>
          ))}

        <AllWatchedMessage />

        {/* Navigation Buttons */}
        {videos.length > 0 && (
          <NavigationBtns
            onDown={handleDownClick}
            onUp={handleUpClick}
            disableUp={currentIndex === 0}
            disableDown={currentIndex === videos.length - 1}
          />
        )}
      </div>
    </>
  );
};

export default CreatorCollabs;

function NavigationBtns({
  onDown,
  onUp,
  disableUp = false,
  disableDown = false,
}) {
  const { isMobile } = useViewport();
  return (
    <section
      className="bottomNavigationButton"
      style={{
        position: "sticky",
        bottom: "1rem",
        right: "2rem",
        display: isMobile ? "none" : "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "center",
        gap: "1rem",
        zIndex: 9999,
        width: "100%",
      }}
    >
      <button
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "transparent",
          border: "2px solid green",
          borderRadius: "50%",
          width: 48,
          height: 48,
          opacity: disableUp ? 0.5 : 1,
        }}
        onClick={onUp}
        disabled={disableUp}
      >
        <HiMiniArrowUp
          style={{
            width: 24,
            height: 24,
            color: "green",
          }}
        />
      </button>
      <button
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "transparent",
          border: "2px solid green",
          borderRadius: "50%",
          width: 48,
          height: 48,
          opacity: disableDown ? 0.5 : 1,
        }}
        onClick={onDown}
        disabled={disableDown}
      >
        <HiMiniArrowDown
          style={{
            width: 24,
            height: 24,
            color: "green",
          }}
        />
      </button>
    </section>
  );
}

function Controls({ isMuted, isPlaying, onPlay, onMute }) {
  const { isMobile } = useViewport();
  return (
    <div
      style={{
        position: "absolute",
        top: isMobile ? 0 : 48,
        left: 0,
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1rem",
        zIndex: 11,
      }}
    >
      <button
        onClick={onPlay}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "transparent",
          borderRadius: "50%",
          border: "none",
          width: 36,
          height: 36,
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        }}
      >
        {isPlaying ? (
          <HiOutlinePause
            style={{
              width: 20,
              height: 20,
              color: "white",
            }}
          />
        ) : (
          <HiOutlinePlay
            style={{
              width: 20,
              height: 20,
              color: "white",
            }}
          />
        )}
      </button>
      <button
        onClick={onMute}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          border: "none",
          width: 36,
          height: 36,
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        }}
      >
        {isMuted ? (
          <HiOutlineSpeakerXMark
            style={{
              width: 20,
              height: 20,
              color: "white",
            }}
          />
        ) : (
          <HiOutlineSpeakerWave
            style={{
              width: 20,
              height: 20,
              color: "white",
            }}
          />
        )}
      </button>
    </div>
  );
}

function ReactionStats({ video, handleReact }) {
  const { isMobile } = useViewport();
  const buttons = [
    {
      img: "../../assets/img/icons/joy.png",
      value: "happy",
    },
    {
      img: "../../assets/img/icons/feelingless.png",
      value: "neutral",
    },
    {
      img: "../../assets/img/icons/angry.png",
      value: "sad",
    },
    {
      img: "../../assets/img/refreshRigns/eyes1.png",
      value: "views",
    },
  ];
  return (
    <div
      className="rightSideButtonsBlock"
      style={{
        position: "absolute",
        // bottom: 24,
        right: isMobile ? 0 : -48,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "1rem",
        padding: "1rem 0rem",
        // backgroundColor: "white",
        borderRadius: 999,
        zIndex: 11,
      }}
    >
      {buttons.map((button, index) => (
        <button
          key={index}
          onClick={
            button.value === "views"
              ? () => {}
              : () => handleReact(button.value, video.id)
          }
          disabled={video.userReaction}
          style={{
            backgroundColor: "transparent",
            border: "none",
            opacity:
              button.value !== "views" &&
              video.userReaction &&
              video.userReaction !== button.value
                ? 0.5
                : 1,
          }}
        >
          <img
            style={{
              width: 32,
              height: 32,
            }}
            className={video.userReaction === button.value ? "zoomInEmoji" : ""}
            src={button.img}
          />
          <p
            className="imogBtn_text"
            style={{
              margin: 0,
              fontWeight: "bold",
            }}
          >
            {button.value === "views"
              ? video.viewCount || 0
              : video?.reactions?.[button.value] || 0}
          </p>
        </button>
      ))}
    </div>
  );
}

function AllWatchedMessage() {
  const { isMobile } = useViewport();
  return (
    <div
      className="slideBlock msg"
      style={
        {
          // width: "100%",
          // maxWidth: "48rem",
          // padding: isMobile ? "1rem 2rem 4rem" : "2rem",
          // display: "flex",
          // flexDirection: "column",
          // alignItems: "center",
          // gap: "1rem",
          // justifyContent: "center",
          // background: "#fff"
        }
      }
    >
      <div
        className="msg-wrapper"
        style={{
          // backgroundColor: "#11a64b",
          // borderRadius: "1rem",
          // padding: "2rem 1rem",
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            margin: "2rem 4rem",
            // color:"#fff",
            gap: "1rem",
          }}
        >
          <div
            style={{
              fontSize: isMobile ? "2rem" : "3rem",
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "1rem",
            }}
          >
            Whoops!
          </div>
          <div
            style={{
              fontSize: isMobile ? "1.3rem" : "1.5rem",
              fontWeight: 400,
              textAlign: "center",
            }}
          >
            You've binge-watched all the exclusive content!
          </div>
          <div
            style={{
              fontSize: isMobile ? "1.3rem" : "1.6rem",
              fontWeight: 400,
              textAlign: "center",
              maxWidth: "36rem",
              marginInline: "auto",
            }}
          >
            Scan the Refresh Rings QR code and come back tomorrow or explore our
            comedy hub.
          </div>
        </div>
      </div>
    </div>
  );
}

const VideoComponent = ({
  video,
  index,
  videoRefs,
  currentIndex,
  isMuted,
  handleTimeUpdate,
}) => {
  const [loading, setLoading] = useState(true);

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div
            style={{
              width: "32px",
              height: "32px",
              border: "4px solid rgba(255, 255, 255, 0.3)",
              borderTop: "4px solid white",
              borderRadius: "50%",
              animation: "spin 1s linear infinite",
            }}
          />
          <style>
            {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
          </style>
        </div>
      )}

      <video
        ref={(el) => (videoRefs.current[index] = el)}
        src={video.url}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        loop
        playsInline
        muted={isMuted}
        onTimeUpdate={(event) => handleTimeUpdate(event, video.id)}
        onCanPlay={() => {
          videoRefs.current[currentIndex].play();
          setLoading(false); // Hide loading spinner when the video is ready
        }}
        onWaiting={() => setLoading(true)} // Show spinner when buffering
        onPlaying={() => setLoading(false)} // Hide spinner when video resumes
        poster={video.thumbnail}
      />
    </div>
  );
};
