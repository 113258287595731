import "./styles/sass/style.scss";
import React, { useEffect, useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PublicLayout from "./components/layouts/Publiclayout";
import { LoaderProvider } from "./utils/context";
import { PopupProvider } from "./components/common/context/popup-context";
import { Toaster } from "react-hot-toast";
import { ViewportProvider } from "./utils/context/viewport-context";
import gluedin from "gluedin";
import ContactUsModal from "./components/modals/contact_us_modal";
import InviteCodeModal from "./components/modals/invite_code_modal";
import ProfileExitPopup from "./components/modals/profile_exit_model";
import ReportJokeModel from "./components/modals/report_joke_modal";
import ReferPopup from "./components/modals/refer_a_friend_model";
import SupriseMe from "./components/common/SupriseMe";
import { GLUEDIN_KEY, GLUEDIN_SECRET } from "./config";
import ClaimSuccess from "./components/modals/claim_success_modal";
import { BottleLoaderProvider } from "./utils/context/bottle-loader-context";

function App() {
  let gluedInSDKInitilize = new gluedin.GluedInSDKInitilize();
  gluedInSDKInitilize.initialize({
    apiKey: GLUEDIN_KEY,
    secretKey: GLUEDIN_SECRET,
  });

  return (
    <ViewportProvider>
      <LoaderProvider>
        <BottleLoaderProvider>
          <PopupProvider>
            <PublicLayout />
            <ContactUsModal />
            <InviteCodeModal />
            <ReportJokeModel />
            <ReferPopup />
            <ProfileExitPopup />
            <SupriseMe />
            <ClaimSuccess />
            <Toaster
              position="top-center"
              reverseOrder={false}
              toastOptions={{
                className: "",
                style: {
                  backgroundColor: "black", // Black background color
                  color: "white", // White text color
                  border: "1px solid #713200",
                  padding: "10px",
                },
                duration: 8000, // Set a duration greater than 0 to remove the close button
                icon: false, // Remove success icon
              }}
            />
          </PopupProvider>
        </BottleLoaderProvider>
      </LoaderProvider>
    </ViewportProvider>
  );
}

export default App;
