import React, { useState } from "react";
import CommonFormikForm from "../../../utils/form";
import useTranslation from "../../../utils/hooks/use-translation";
import {
  mobileNoValidationSchema,
  referCodeValidationSchema,
} from "../../../utils/validations";
import { sendReferralAPI, verifyRefCodeAPI } from "../../../services";
import useLoader from "../../../utils/hooks/use-loader";
import toast from "react-hot-toast";
import { MetaPixel, handleErrorMessages } from "../../../utils/helpers";
import AppModal from "../../modals";
import { Button } from "react-bootstrap";
import { setReferFriend } from "../../../store/slices/common-slice";
import { useDispatch, useSelector } from "react-redux";
import { getCDSPixelId } from "../../../utils/helpers";
import { getAllRewards } from "../../../services";
import { setHomePageDetails } from "../../../store/slices/common-slice";

const ReferAFriendForm = ({
  getReferralList,
  type,
  show = false,
  onClose = {},
  setShowThankYouModal = () => {},
  setReferralCode = () => {},
}) => {
  const { t } = useTranslation();
  const { toggleLoader } = useLoader();
  const dispatch = useDispatch();

  const currentLanguageID = useSelector(
    (state) => state.common.currentLanguageID
  );

  const initialValues = {
    mobile: "",
  };

  const initialValues2 = {
    code: "",
  };

  const [referPopupText, setReferPopupText] = useState("");
  const [referError, setReferError] = useState("");

  const referText = (data) => {
    setReferPopupText(data);
  };

  //handle submit button action
  const handleSubmit = async (values, { setFieldError, resetForm }) => {
    MetaPixel("ReferNow");
    if (referPopupText !== "Same user") {
      toggleLoader(true);
      if (type === "refer_code") {
        verifyRefer(values, setFieldError, resetForm);
      } else {
        sendRefer(values, setFieldError, resetForm);
      }
    }
  };

  const onCloseRefModal = (data) => {
    setReferralCode(data?.data?.data);
    onClose();
    // openReferFriendPopup()
    if (data?.data?.succeeded) {
      setTimeout(() => {
        setShowThankYouModal(true);
      }, 400);
    }
  };

  const getAllRewardsData = async () => {
    const hfSessionKey = await getCDSPixelId();

    const rewardsRes = await getAllRewards({
      languageId: currentLanguageID || "1",
      hfSession: hfSessionKey,
    });

    dispatch(
      setHomePageDetails({
        coinCount: rewardsRes?.data?.data?.currentBalance ?? 0,
      })
    );
  };

  //send refer api call
  const sendRefer = async (values, setFieldError, resetForm) => {
    try {
      window.dataLayer.push({
        event: "Sprite_Refer_A_Friend",
      });
      let reqData = {
        referTo: values.mobile,
        hfSession: "",
      };
      const res = await sendReferralAPI(reqData);
      if (res.data && res.data.succeeded) {
        // toast.success(t.messages.refer_success);
        onCloseRefModal(res);
        resetForm();
        getReferralList();
        dispatch(setReferFriend(false));
        //GA event added for successfully refer number
        window.dataLayer.push({ event: "Sprite24_ReferNow" });

        getAllRewardsData();
      } else {
        handleErrorMessages(res.data, setFieldError);
        dispatch(setReferFriend(false));
      }
    } catch (err) {
      const referErrorMessage = err?.response?.data?.message;
      if (referErrorMessage === "Referral already sent.") {
        setReferPopupText("Already referred");
      } else {
        setReferError(referErrorMessage);
      }
    } finally {
      toggleLoader(false);
    }
  };

  //verify refer code api call
  const verifyRefer = async (values, setFieldError, resetForm) => {
    try {
      let reqData = {
        refCode: values.code,
      };
      const res = await verifyRefCodeAPI(reqData);
      if (res.data && res.data.succeeded) {
        //GA event added for successfully refer number
        window.dataLayer.push({ event: "Sprite24_ReferralCode_Submit" });
        // toast.success(t.messages.refer_code_success);
        resetForm();
        getReferralList();
        onClose();
      } else {
        let message = res.data && res.data.message;
        setFieldError("code", message);
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  };

  const renderInput = [
    {
      type: "text",
      name: "mobile",
      placeHolder: t.common.enter_friend_mobile_number,
      inputType: "number",
    },
  ];

  const renderInput2 = [
    {
      type: "text",
      name: "code",
      placeHolder: "XXXXXX",
      inputType: "text",
      label: t.common.enter_referal_code,
    },
  ];

  let referCodeValidation = referCodeValidationSchema(t);
  let mobileNoValidation = mobileNoValidationSchema(t);

  const handleReferAnother = () => {
    setReferPopupText("Valid");
  };

  const popupTextMap = {
    "Not a valid number": {
      title: "Trying to prank us?",
      message:
        "This number is not valid. 🙅‍♂️ Retry or refer another friend to collect Comic Coins",
    },
    Valid: {
      title: t.referal.bro_code_we_follow,
      message: t.referal.get_friend_laughing_subheading,
    },
    "Same user": {
      title: t.model_messages.ahem_ahem,
      message: t.model_messages.self_love,
    },
    "Already referred": {
      image: (
        <img
          src="/assets/img/modal/reminder-modal-icon.svg"
          alt="Icon"
          className="modal-icon mb-md-4 mb-2"
        />
      ),
      title: t.model_messages.trying_to_get_one_past,
      message:
        "You've already referred this person. 👐 Try sending them a reminder, instead! ",
      button: (
        <Button
          type="submit"
          variant="primary"
          className="btn-block py-4"
          onClick={handleReferAnother}
        >
          Refer Another
        </Button>
      ),
    },
  };

  const { title, message, button, image } =
    popupTextMap[referPopupText] || popupTextMap["Valid"];

  return (
    <>
      {type === "refer_code" ? (
        <AppModal
          show={show}
          onClose={onClose}
          bodyClass="px-4 py-5  modal-unique-code"
        >
          <CommonFormikForm
            initialValues={initialValues2}
            onSubmit={handleSubmit}
            renderInputs={renderInput2}
            ButtonText="Submit"
            validationSchema={referCodeValidation}
            referConditionsText=""
          />
        </AppModal>
      ) : type === "refer_number" ? (
        <AppModal
          show={show}
          onClose={onClose}
          bodyClass="px-4 py-5   modal-unique-code text-center"
        >
          {image}
          <h5 className="modal-title">{title}</h5>
          <p className="mb-4 modal-sub-title sm">{message}</p>
          {button}
          {referPopupText !== "Already referred" && (
            <CommonFormikForm
              initialValues={initialValues}
              onSubmit={handleSubmit}
              renderInputs={renderInput}
              ButtonText={t.common.refer_now}
              validationSchema={mobileNoValidation}
              referConditionsText={referText}
              referError={referError}
              setReferError={setReferError}
            />
          )}
        </AppModal>
      ) : (
        <CommonFormikForm
          formClass={"d-flex align-items-center"}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          renderInputs={renderInput}
          ButtonText="Refer Now"
          submitBtnClass={"refer-now-btn"}
          validationSchema={mobileNoValidation}
          referConditionsText=""
          referError={referError}
          setReferError={setReferError}
        />
      )}
    </>
  );
};

export default ReferAFriendForm;
