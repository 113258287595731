import { Button } from "react-bootstrap";

export const bannerData = [];

export const latesteTuneData = [
  {
    image: "/assets/img/latestunes/img01.webp",
    altText: "img01",
  },
  {
    image: "/assets/img/latestunes/img01.webp",
    altText: "img02",
  },
  {
    image: "/assets/img/latestunes/img01.webp",
    altText: "img03",
  },
  {
    image: "/assets/img/latestunes/img01.webp",
    altText: "img04",
  },
];

export const behindTheSceneData = [
  {
    image: "/assets/img/behindthescene/img01.webp",
    altText: "img01",
  },
  {
    image: "/assets/img/behindthescene/img01.webp",
    altText: "img02",
  },
  {
    image: "/assets/img/behindthescene/img01.webp",
    altText: "img03",
  },
  {
    image: "/assets/img/latestunes/img01.webp",
    altText: "img04",
  },
];
export const liveStreamingData = [
  {
    image: "/assets/img/livestreaming/img01.webp",
    altText: "img01",
  },
];
export const melodiesRewindData = [
  {
    image: "/assets/img/seasonalhits/img01.webp",
    altText: "img02",
  },
  {
    image: "/assets/img/seasonalhits/img02.webp",
    altText: "img02",
  },
  {
    image: "/assets/img/seasonalhits/img03.webp",
    altText: "img03",
  },
];

export const rewardPooldata = [
  {
    image: "assets/img/rewards/img01.webp",
    title: "Win a Ticket to Tomorrowland",
    text: "Stand a chance to win an all expenses paid trip to Europe and experience Tomorrowland in person",
    points: "200",
    pointsCount: "1",
    pointTitle: "By Using",
    buttonText: "Collect Now",
  },
  {
    image: "assets/img/rewards/img02.webp",
    title: "Win Music Fest Ticket3",
    text: "Stand a chance to win a ticket to experience Music Fest.",
    points: "202",
    pointsCount: "2",
    pointTitle: "By Using 2",
    buttonText: "Collect Now",
  },
  {
    image: "assets/img/rewards/img03.webp",
    title: "Win Music Fest Ticket",
    text: "Stand a chance to win a ticket to experience Music Fest.",
    points: "2003",
    pointsCount: "3",
    pointTitle: "By Using 3",
    buttonText: "Collect Now",
  },
  {
    image: "assets/img/rewards/img01.webp",
    title: "Win Music Fest Ticket 4",
    text: "Stand a chance to win a ticket to experience Music Fest.",
    points: "2004",
    pointsCount: "4",
    pointTitle: "By Using 4",
    buttonText: "Collect Now",
  },
];

export const walletdata = [
  {
    title: "Harmony",
    points: "200",
    totalPoints: "1000",
    cardClassName: "Harmony",
  },
  {
    title: "Bass",
    points: "202",
    totalPoints: "1001",
    cardClassName: "Bass",
  },
  {
    title: "Aldo",
    points: "2003",
    totalPoints: "1002",
    cardClassName: "Aldo",
  },
  {
    title: "Echo",
    points: "2004",
    totalPoints: "1003",
    cardClassName: "Echo",
  },
];

export const treasureData = [
  {
    image: "/assets/img/treasures/img01.webp",
    title: "Mass Merch",
    likes: "3",
    views: "5",
  },
  {
    image: "/assets/img/treasures/img01.webp",
    title: "Class Merch",
    likes: "5",
    views: "8",
  },
  {
    image: "/assets/img/treasures/img01.webp",
    title: "Mass Merch",
    likes: "9",
    views: "3",
  },
  {
    image: "/assets/img/treasures/img01.webp",
    title: "Class Merch",
    likes: "38",
    views: "500",
  },
];

export const headsData = [
  {
    image: "/assets/img/studioheads/img01.webp",
    title: "Mass Merch",
    likes: "3",
    views: "5",
  },
  {
    image: "/assets/img/studioheads/img02.webp",
    title: "Class Merch",
    likes: "5",
    views: "8",
  },
  {
    image: "/assets/img/studioheads/img03.webp",
    title: "Mass Merch",
    likes: "9",
    views: "3",
  },
  {
    image: "/assets/img/studioheads/img03.webp",
    title: "Class Merch",
    likes: "38",
    views: "500",
  },
];

const handleButtonClick = () => {
  window.location.href = "/ugc";
};

export const artistList = [
  {
    id: 1,
    name: "Afroto",
    title: "Learn more about your favorite artist",
    description:
      "Jon Batiste is one of history's most brilliant, prolific, and accomplished musicians. Batiste studied and received both a B.A. and M.F.A. at the world-renowned Juilliard School in New York City. In 2020, he won an Academy Award for Best Original Score for the Disney/Pixar film SOUL, an honor he shared with fellow composers Trent Reznor and Atticus Ross. He is the second black composer in history, after legendary jazz musician Herbie Hancock, to win an Academy Award for composition. Batiste's latest studio album, WE ARE, was released in March 2021 to overwhelming critical acclaim.",
    linkText: "Explore",
    image: "/assets/img/artist.png",
    popularity: 120,
    views: 1000,
    likes: 500,
  },
  {
    id: 2,
    name: "Camilo",
    title: "Learn more about your favorite artist",
    description:
      "Jon Batiste is one of history's most brilliant, prolific, and accomplished musicians.",
    linkText: "Explore",
    image: "/assets/img/artist.png",
    popularity: 100,
    views: 4000,
    likes: 60,
  },
  {
    id: 3,
    name: "Cat Burns",
    title: "Learn more about your favorite artist",
    description:
      "Jon Batiste is one of history's most brilliant, prolific, and accomplished musicians.",
    linkText: "Explore",
    image: "/assets/img/artist.png",
    popularity: 30,
    views: 2000,
    likes: 60,
  },
  {
    id: 4,
    name: "Afroto",
    title: "Learn more about your favorite artist",
    description:
      "Jon Batiste is one of history's most brilliant, prolific, and accomplished musicians.",
    linkText: "Explore",
    image: "/assets/img/artist.png",
    popularity: 120,
    views: 1000,
    likes: 500,
  },
  {
    id: 5,
    name: "Camilo",
    title: "Learn more about your favorite artist",
    description:
      "Jon Batiste is one of history's most brilliant, prolific, and accomplished musicians.",
    linkText: "Explore",
    image: "/assets/img/artist.png",
    popularity: 120,
    views: 1000,
    likes: 500,
  },
  {
    id: 6,
    name: "Cat Burns",
    title: "Learn more about your favorite artist",
    description:
      "Jon Batiste is one of history's most brilliant, prolific, and accomplished musicians.",
    linkText: "Explore",
    image: "/assets/img/artist.png",
    popularity: 120,
    views: 1000,
    likes: 500,
  },
];

export const karaokeData = [
  {
    id: 1,
    image: "/assets/img/karaokeon/img01.webp",
    title: "Get Your Karaoke On",
    description:
      "Use the exclusive Coke® Studio Karaoke to record yourself singing alongto your favorites, share with your friends and brag away!",
    linkText: "Let’s Start",
  },
];

export const menuData = (t) => {
  let data = [
    {
      id: 1,
      image: "/assets/img/icons/side-nav-icons/home-icon.svg",
      title: t.header.home,
      url: "/",
    },
    {
      id: 2,
      image: "/assets/img/icons/side-nav-icons/contest-icon.svg",
      title: t.common.contest,
      url: "/contest",
    },

    {
      id: 3,
      image: "/assets/img/icons/side-nav-icons/explore.svg",
      title: t.header.explore,
      url: "#",
      subMenu: [
        // {
        //   id: 1,
        //   title: t.home.creator_collabs_heading,
        //   url: "/creator-collabs",
        // },
        // {
        //   id: 1,
        //   title: t.home.jokers_bottle,
        //   url: "/artist",
        // },
        {
          id: 2,
          title: t.wallet.comic_kaun,
          url: "/ugc_upload",
        },
        {
          id: 3,
          title: t.home.pick_your_mood,
          url: "/#pick_mood",
        },

        {
          id: 4,
          title: t.home.joke_box,
          url: "/ugc",
        },
        {
          id: 5,
          title: t.header.hall_of_lame,
          url: "/hall_lame",
        },
        {
          id: 6,
          title: t.referal.refer_a_friend,
          url: "profile",
          state: { showRefer: true },
          // scrollTo: "refer_friend"
        },
        {
          id: 7,
          title: t.header.have_an_invite_code,
          url: "invite-code",
          state: { showInvite: true },
        },
        {
          id: 8,

          buttonComponent: (
            <Button
              variant={"primary"}
              className="btn-block btn-sidebar"
              onClick={handleButtonClick}
            >
              <div className="user-card">
                <div className="user-details">
                  <p className="mb-0 user-subtitle">
                    {t.common.vote_funniest_pjs}
                  </p>
                  <h6 className="user-title">{t.common.collect_comic_coins}</h6>
                </div>
              </div>
            </Button>
          ),
        },
      ],
    },
  ];

  return data;
};

export const menuData2 = (t) => {
  let data = [
    {
      id: 1,
      image: "/assets/img/icons/side-nav-icons/contact-us.svg",
      title: t.header.contact_us,
      url: "contact-us",
    },
    {
      id: 2,
      image: "/assets/img/icons/side-nav-icons/faq.svg",
      title: t.header.faq,
      url: "faq",
    },
    {
      id: 3,
      image: "/assets/img/icons/side-nav-icons/TnC.svg",
      title: t.header.terms_and_conditions,
      url: "terms_conditions",
    },
    {
      id: 4,
      image: "/assets/img/icons/side-nav-icons/privacyPolicy.svg",
      title: t.header.privacy_policy,
      url: "privacy_policy",
    },
  ];
  return data;
};

export const appendObjectToFormData = (formData, object) => {
  Object.entries(object).forEach(([key, value]) => {
    formData.append(key, value);
  });
};

export const languageMap = {
  English: 1,
  हिन्दी: 2,
  తెలుగు: 3,
  ଓଡ଼ିଆ: 4,
  বাংলা: 5,
  मराठी: 6,
  ಕನ್ನಡ: 7,
  ગુજરાતી: 8,
  भोजपुरी: 9,
  मैथिली: 10,
};

export const languages = [
  {
    id: 1,
    lang: "English",
    langScript: "English",
  },
  {
    id: 2,
    langScript: "हिन्दी",
    lang: "Hindi",
  },
  {
    id: 3,
    langScript: "తెలుగు",
    lang: "Telugu",
  },
  {
    id: 4,
    langScript: "ଓଡ଼ିଆ",
    lang: "Oriya",
  },
  {
    id: 5,
    langScript: "বাংলা",
    lang: "Bengali",
  },
  {
    id: 6,
    langScript: "मराठी",
    lang: "Marathi",
  },
  {
    id: 7,
    langScript: "ಕನ್ನಡ",
    lang: "Kannada",
  },
  // {
  //   id: 8,
  //   langScript : "ગુજરાતી",
  //   lang : "Gujarati",
  // },
  {
    id: 9,
    langScript: "भोजपुरी",
    lang: "Bhojpuri",
  },
  {
    id: 10,
    langScript: "मैथिली",
    lang: "Maithli",
  },
];
