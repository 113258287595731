import React, { useState, useEffect } from 'react';
import useTranslation from '../../utils/hooks/use-translation';
import { Container } from 'react-bootstrap';
import { differenceInSeconds, setHours, setMinutes, setSeconds, addDays, isAfter } from 'date-fns';

const CountDown = () => {
  const { t } = useTranslation();
  const targetDate = new Date('2024-07-15T00:00:00'); // Target date and time

  const calculateNextEightPM = () => {
    const now = new Date();
    let nextEightPM = setSeconds(setMinutes(setHours(now, 20), 0), 0); // Set time to 8 PM

    if (isAfter(now, nextEightPM)) {
      nextEightPM = addDays(nextEightPM, 1); // Move to the next day if it's already past 8 PM today
    }

    return nextEightPM;
  };

  const calculateTimeLeft = () => {
    const now = new Date();
    const targetDate = calculateNextEightPM();
    const difference = differenceInSeconds(targetDate, now);
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (60 * 60 * 24)),
        hours: Math.floor((difference % (60 * 60 * 24)) / (60 * 60)),
        minutes: Math.floor((difference % (60 * 60)) / 60),
        seconds: Math.floor(difference % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const splitDigits = (number) => {
    return String(number).padStart(2, '0').split('');
  };

  return (
    <Container>
      <div className='d-flex justify-content-between justify-content-md-end mt-5 mb-n2 mb-md-0 mt-md-12 timer-static'>
        <h4 className='mt-1'>{t.common.announcing_the_winners}</h4>
        <div className='d-flex'>
          <div className='timer hours'>
            <div className='d-flex flex-column align-items-center'>
              <h3>
                {splitDigits(timeLeft.hours).map((digit, index) => (
                  <span key={index}>{digit}</span>
                ))}
              </h3>
              <p>{t.common.hours}</p>
            </div>
          </div>
          <strong>:</strong>
          <div className='timer minutes'>
            <div className='d-flex flex-column align-items-center'>
              <h3>
                {splitDigits(timeLeft.minutes).map((digit, index) => (
                  <span key={index}>{digit}</span>
                ))}
              </h3>
              <p>{t.common.minutes}</p>
            </div>
          </div>
          <strong>:</strong>
          <div className='timer seconds'>
            <div className='d-flex flex-column align-items-center'>
              <h3>
                {splitDigits(timeLeft.seconds).map((digit, index) => (
                  <span key={index}>{digit}</span>
                ))}
              </h3>
              <p>{t.common.seconds}</p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CountDown;
